<template>
  <div class="book-info form-group">
    <label>{{$t('checkout.labelOrderInfo')}}</label>
    <div class="row book-info-main">
      <div class="col-xs-4 col-sm-6 col-md-4">
        <cover :book="book" v-if="bookLoaded" />
      </div>
      <div class="col-xs-8 col-sm-6 col-md-8 text-small" v-if="bookLoaded">
        <p>
          <i :class="['fa', iconClass]"></i>
          <span> {{book.design.title}}</span>
        </p>
        <template v-if="book.type !== 'calendar'">
          <p>{{$t('book.author')}}: {{book.design.author}}</p>
        </template>
        <p>{{$t('book.pages')}}: {{$t('book.page', {page: book.totalPage})}}</p>
        <p>{{$t('book.typeset')}}: {{typeset}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'src/app.config'
import Cover from '../../../components/Cover'
import { find } from 'lodash'
const book = {
  title: '',
  design: {
    title: '',
    author: '',
    template: 'type-1',
    cover: {
      imageUrl: ' ',
      color: '#fff'
    }
  }
}
export default {
  name: 'order-info',
  components: {
    Cover
  },
  props: {
    book: {
      type: Object,
      default: function () {
        return Object.assign({}, book)
      }
    },
    quantity: {
      type: [String, Number],
      default: '1'
    },
    binding: {
      type: String,
      default: 'hardback'
    },
    bookLoaded: Boolean
  },
  data () {
    return {
    }
  },
  computed: {
    iconClass () {
      switch (this.book.type) {
        case 'calendar':
          return 'fa-calendar text-primary'
        case 'photobook':
          return 'fa-photo text-primary'
        case 'diarybook':
        default:
          return 'fa-book text-fb'
      }
    },
    typeset () {
      if (!this.bookLoaded) return ''
      if (this.book.type === 'calendar') {
        return '月曆'
      }
      const { template } = this.book.design
      if (template.includes('magazine') || template.includes('album')) {
        return '相片書' + (/magazine/.test(template) ? '海量冊' : '雜誌冊')
      }
      const typeset = find(config.templates, { name: this.book.typeset })
      return this.$t(typeset.desc)
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>

  .book-info {
    img {
      box-shadow: $dp-1;
    }
    &-main {
      display: flex;
      align-items: center;
    }
  }
</style>
