<template>
  <div class="coupon-item" :class="[{'disable': !enable}, coupon.bookType, {'coupon-item__disabled': !canUsed}]" v-if="!visible">
    <div class="coupon-item__discount">
      <div>
        <p class="discount-title">{{scope}}</p>
        <p>
          <span v-if="coupon.type === 'cash'">{{symbol}}</span>
          <strong>{{coupon.type === 'discount' || coupon.type === 'bulk' ? discount : coupon.value}}</strong><span v-if="(coupon.type === 'discount' || coupon.type === 'bulk') && coupon.value">% off</span>
          <span v-if="coupon.type === 'voucher'"> {{$t('coupons.labelPage')}}</span>
        </p>
      </div>
    </div>
    <div class="coupon-item__info">
      <ul>
        <li>{{$t('coupons.labelCount', {count: restCount})}}<span v-if="language === 'en' && restCount > 1">s</span></li>
        <li v-if="canUsed">{{$t('coupons.labelExpires', {expires: endDate})}}</li>
        <li v-else>未開始</li>
        <li v-html="tips"></li>
        <li v-if="coupon.rules.minPages && language !== 'en'"><b>{{$t('coupons.labelConfPageSuit', {page: coupon.rules.minPages})}}</b></li>
      </ul>
    </div>
    <div class="coupon-item__action" :class="{used: active}" v-if="available && canUsed" @click="selectCoupon">
      <template v-if="active">
        正在使用
      </template>
      <template v-else>
        {{$t('coupons.buttonUse')}}
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import currency from 'src/mixins/currency'

export default {
  name: 'coupon',
  mixins: [currency],
  props: {
    coupon: {
      type: Object,
      default: function () {
        return {
          couponNo: '123456',
          endAt: '2017-11-29T16:00:00Z',
          type: 'discount',
          used: 12,
          total: 1000,
          value: {
            value: 0.3
          }
        }
      }
    },
    available: {
      type: Boolean,
      default: false
    },
    enable: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    visible () {
      return this.coupon.scope === 'group_buying' && this.coupon.type === 'discount' && this.coupon.value === 1
    },
    endDate () {
      return this.$moment(this.coupon.endAt).format('YYYY/MM/DD HH:mm')
    },
    scope () {
      switch (this.coupon.bookType) {
        case 'calendar':
          return '月曆優惠'
        case 'photobook':
          return '相片書優惠'
        case 'all':
          return '通用券'
        case 'diarybook':
        default:
          return '時光書優惠'
      }
    },
    // 使用剩余次数
    restCount () {
      switch (this.coupon.scope) {
        case 'activity': // 活动优惠券的剩余次数 = 领取次数 - 使用次数
          return this.coupon.rest
        case 'platform': // 平台优惠券的剩余次数 = 总使用次数 - 已使用次数
          return this.coupon.total - this.coupon.used
        case 'user': // 个人优惠券的剩余次数 = 总次数 - 使用次数
          return this.coupon.total - this.coupon.used
        case 'agency': // 代理优惠券的剩余次数 = 总次数 - 使用次数
          return this.coupon.total - this.coupon.used
        default:
          return 1
      }
    },
    discount () {
      if (this.coupon.type === 'cash') {
        return this.symbol + this.coupon.value
      } else if (this.coupon.type === 'voucher') {
        return this.$t('coupons.labelVoucherName')
      } else {
        if (!this.coupon.value) {
          return this.$t('coupons.labelFree')
        }
        return Math.round(100 - 100 * this.coupon.value)
      }
    },
    couponType () {
      if (this.coupon.scope === 'group_buying') {
        return this.$t('coupons.labelGroupCoupon')
      }
      switch (this.coupon.type) {
        case 'voucher':
          return this.$t('coupons.labelVoucherName')
        case 'cash':
          return this.$t('coupons.labelCash')
        case 'discount':
        default:
          return this.$t('coupons.labelDiscount')
        case 'bulk':
          return this.$t('coupons.labelBulk')
      }
    },
    tips () {
      const { bookType, rules } = this.coupon
      if (bookType === 'all') {
        return '適用於所有產品'
      }
      if (bookType === 'photobook') {
        const tip = rules.templates
          ? rules.templates[0] === 'album' ? 'A4輕奢雜誌冊' : '海量冊'
          : ''
        return `僅適用於相片書${tip}` + (rules.freeDelivery ? ' + 免郵' : '')
      }
      if (bookType === 'calendar') {
        return '僅適用於月曆' + (rules.freeDelivery ? ' + 免郵' : '')
      }
      if (rules.typeset) {
        return /A4/gi.test(rules.typeset)
          ? this.$t('coupons.labelConfTypesetA4', { typeset: this.coupon.rules.typeset })
          : this.$t('coupons.labelConfTypesetA5', { typeset: this.coupon.rules.typeset })
      }
      if (this.coupon.type !== 'voucher') {
        return this.$t('coupons.labelConf')
      } else {
        let expressTip = ''
        if (rules.freeDelivery) {
          expressTip = this.$t('coupons.labelFreeDelivery')
        }
        return this.$t('coupons.labelVoucher', { page: this.coupon.value }) + ` + ${expressTip}`
      }
    },
    canUsed () {
      return this.coupon.rules.groupAvaliable || this.coupon.scope !== 'group_buying'
    }
  },
  created () {},
  methods: {
    selectCoupon () {
      if (this.canUsed) {
        this.$emit('use', this.coupon)
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/layout/Coupon';
</style>
