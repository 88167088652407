<template>
  <div class="address-editor">
    <p class="address-editor__header">{{$t('address.titleEdit')}}</p>
    <div class="row">
      <div class="col-xs-10">
        <div class="form-group text-primary">
          <b>{{$t('address.labelRequired')}}{{showTip ? '，台灣、香港、澳門請填寫中文姓名' : ''}}</b></div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelLastName')}} *</label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('lastName') > -1}"
                 v-model.trim="address.lastName">
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelFirstName')}} *</label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('firstName') > -1}"
                 v-model.trim="address.firstName">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>{{$t('address.labelLine1')}} *</label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('line1') > -1}"
                 v-model.trim="address.line1">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>{{$t('address.labelLine2')}} </label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('line2') > -1}"
                 v-model.trim="address.line2">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>{{$t('address.labelCity')}} *</label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('city') > -1}"
                 v-model.trim="address.city">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelState')}} *</label>
          <template v-if="showStateInput">
            <input class="form-control"
                   :class="{'is-danger': validateList.indexOf('state') > -1}"
                   v-model.trim="address.state">
          </template>
          <template v-else>
            <select v-model="address.state" :class="{'is-danger': validateList.indexOf('state')> -1}">
              <option :value="item.value" :key="index" v-for="(item, index) in cities">{{item.label}}</option>
            </select>
          </template>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelCountry')}} *</label>
          <select v-model="address.countryCode">
            <option :value="item.value" v-for="(item, index) in areas" :key="index">{{item.label}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelPostalCode')}} *</label>
          <input type="text" class="form-control" :class="{'is-danger': validateList.indexOf('postalCode')> -1}"
                 v-model.trim="address.postalCode">
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{$t('address.labelPhone')}} *</label>
          <input class="form-control" :class="{'is-danger': validateList.indexOf('phone')> -1}"
                 v-model.trim="address.phone">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>{{$t('address.labelEmail')}} *</label>
          <input type="text" class="form-control" :class="{'is-danger': validateList.indexOf('email')> -1}"
                 v-model.trim="address.email">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import region from '../../../../static/json/region.json'
import countries from '../../../../static/json/country.json'
import { mapActions, mapGetters } from 'vuex'
import find from 'lodash/find'
import api from '../../../api/'

export default {
  name: '',
  props: {
    ad: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({
    address: {
      lastName: '',
      firstName: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      countryCode: '',
      postalCode: '',
      phone: '',
      email: ''
    },
    validateList: [],
    areas: [],
    showStateInput: false
  }),
  computed: {
    ...mapGetters(['locale']),
    country () {
      return region.map(item => {
        return {
          value: item.value,
          label: item.label
        }
      })
    },
    cities () {
      if (!this.areas.length) return []
      const value = this.address.countryCode
      if (value) {
        return find(this.areas, { value }).district
      } else {
        return []
      }
    },
    showTip () {
      const value = this.address.countryCode
      return value === 'TW' || value === 'HK' || value === 'MO'
    }
  },
  watch: {
    'address.countryCode' (val) {
      if (!this.areas.length) return
      this.address.country = find(countries, { alias: val }).label
      if (val !== this.$store.state.locale && val) {
        const cities = find(this.areas, { value: val }).district || []
        if (cities.length) {
          this.address.state = cities[0].value
        } else {
          this.address.state = ''
          this.showStateInput = true
        }
      }
    },
    'address.state' (val) { // 香港，澳门地区的城市默认为区
      if (['HK', 'MO'].includes(this.address.countryCode)) {
        this.address.city = val
      }
    }
  },
  created () {
    this.address = Object.assign({}, this.address, this.ad)
    this.fetchAddress().then(data => {
      if (!this.address.countryCode) {
        this.address.countryCode = this.locale
        const cities = find(data, { value: this.address.countryCode }).district || []
        if (cities.length) {
          this.address.state = cities[0].value
        } else {
          this.showStateInput = true
        }
      } else {
        const cities = find(data, { value: this.address.countryCode }).district || []
        if (!cities.length) {
          this.showStateInput = true
        }
      }
    })
  },
  methods: {
    ...mapActions([
      'setLocale'
    ]),
    fetchAddress () {
      return api.fetchCountries().then(data => {
        data = data.data
        this.areas = data.map(item => {
          item.value = item.countryCode
          return item
        }).filter(item => {
          const codes = countries.map(i => i.alias)
          return codes.includes(item.countryCode)
        })
        return this.areas
      })
    }
  }
}
</script>

<style lang="scss">
.address-editor {
  .address-editor__header {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: .75rem;
  }

  .form-control {
    font-weight: 500;

    &.is-danger {
      border-color: $el-color-danger;
    }
  }

  $input-bg-color: #fff;
  $input-border-color: #bdbdbd;
  $input-active-color: $el-color-link;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    font-size: 1rem;
    font-weight: inherit;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    background: $input-bg-color url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="' + $input-border-color + '" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &.is-danger {
      border-color: red;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      background: $input-bg-color url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="' + $input-active-color + '" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
    }
  }
}
</style>
