<template>
  <div class="count">
    <input ref="input" type="number" v-model="data" @change="checkNum">
    <button class="count-decrease" :class="{'disable': data -0 <= 1}" @click="decrease">
      <i class="fa fa-minus"></i>
    </button>
    <button class="count-increase" @click="increase">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'number',
  props: {
    number: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      data: ''
    }
  },
  created () {
    this.data = this.number
  },
  methods: {
    checkNum (e) {
      const value = e.target.value
      if (value <= 1) {
        this.data = 1
        this.$emit('change', 1)
      } else {
        this.$emit('change', value)
      }
    },
    decrease () {
      const value = this.$refs.input.value
      if (value - 0 <= 1) {
        this.$emit('change', 1)
        return
      }
      this.data--
      this.$emit('change', this.data)
    },
    increase () {
      this.data++
      this.$emit('change', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.count {
  display: inline-flex;
  width: auto;
  border: 1px solid #ccc;
  border-radius: 4px;

  input, button {
    float: left;
  }

  input {
    border: 0;
    text-indent: 14px;
    background: transparent;
    color: #555;
    font-weight: 500;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }

  button {
    padding: 0;
    border-radius: 0;
    width: 38px;
    text-align: center;
    font-size: 20px;
    border-left: 1px solid #ccc;
    height: 34px;

    &.disable {
      color: #dfdfdf;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    border: 0;
    border-radius: 0;
    input {
      border-radius: 0;
      border-bottom: 1px solid #ccc;
      transition: all .35s;

      &:focus {
        border-color: $el-color-primary;
      }
    }
    button {
      width: 42px;
      margin-left: .5rem;
      border: 0;
      background: $el-color-primary;
      border-radius: 4px;
      color: white;
      box-shadow: $dp-1;
      transition: all .35s;

      &.disable {
        background: lighten(#9f9f9f, 10%);
      }

      &:hover {
        color: white;
        box-shadow: $dp-2;
      }
    }
  }
}
</style>
